@import "~antd/dist/antd.less";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  transition: all 0.2s;
}
body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

@primary-color: #00962C;@success-color: #00962C;@warning-color: #B28A48;@error-color: #FF612F;@black: #151515;@component-background: #f9f9f9;@heading-color: #272D4E;@text-color: #272D4E;@text-color-secondary: @black;@highlight-color: #92C064;@border-radius-base: 5px;@btn-default-border: @primary-color;@btn-default-color: @primary-color;@btn-height-lg: 3rem;@table-header-color: rgba(0,0,0,.3);@font-family: 'Lato', 'Circular STD', sans-serif;@font-size-base: 18px;