.container {
  width: 100%;
  padding: 1rem;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.truck-items {
  margin-top: 1rem;
}
.details-content {
  width: 100%;
  display: flex;
  justify-content: center;
  /* font-size: 1.5rem; */
}
.details-card {
  background-color: #f9f9f9;
  width: 100%;
  padding: 1.3rem;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: space-between;
  text-transform: capitalize;
}
.details-card b {
  text-align: right;
}
.details-card:not(:last-child) {
  margin-bottom: 1rem;
}
.details-card > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.8em;
}
.details-card > span:not(:last-child) {
  padding-bottom: 1em;
}
.details-container .main-body {
  margin-top: 2rem;
}
.details-card > span b {
  text-align: left;
}
@media (min-width: 600px) {
  .header {
    flex-direction: row;
  }
  .details-card {
    width: 70%;
  }

  .details-card:not(:last-child) {
    margin-bottom: 0rem;
  }
  .details-topbar > *:not(:first-child) {
    padding-top: 0rem;
  }
  .details-card > span {
    flex-direction: row;
    align-items: center;
  }
  .details-card > span b {
    text-align: right;
  }
}

.unavailable {
  opacity: 0.5;
  filter: grayscale(100%);
}
