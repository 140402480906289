.container {
  background-color: #fff;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-flow: row wrap;
  padding: 1rem 0;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}

.offers {
  position: relative;
  height: inherit;
  width: 50%;
  background-color: #efefef;
  overflow: hidden;
  /* background: -webkit-linear-gradient(45deg, #2c009655, #00962c55); */
}

.object-1,
.object-2,
.object-3 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  /* border: 20px solid #2c009622; */
  position: absolute;
}
.object-1 {
  top: -24%;
  right: -20%;
  border: 5px solid #2c009622;
}
.object-2 {
  border: 25px solid #00962c22;
  top: 0;
  left: -10%;
  transform: scale(1.5);
}
.object-3 {
  border: 20px solid #ffcb0422;
  bottom: -10%;
  left: 35%;
}
.offers-content {
  background-color: transparent;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  height: 80%;
}
.offers-content h3 {
  padding: 0;
  margin: 0;
}
.offer-header {
  font-weight: 600;
  margin-top: 3rem;
}
.offer-item {
  background-color: #ffffffdd;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 320px;
  overflow: hidden;
  box-shadow: 0 2px 10px #dcdcdc;
}
.offer-img {
  padding: 1rem 1.5rem;
  border-right: 1px solid #272d4e;
}
.offer-text {
  padding-left: 1rem;
}

.auth-form {
  /* height: inherit; */
  overflow-y: auto;
  width: 40%;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .container {
    overflow-y: auto;
  }
  .content {
    width: 85%;
    margin-top: 2rem;
  }
  .offers {
    width: 100%;
    border: 1px solid red;
    display: none;
  }
  /* .offer-item {
    width: 250px;
  }
  .offer-img {
    padding: 0.5rem 1rem;
    border-right: 1px solid #272d4e;
  } */
  .auth-form {
    width: 100%;
  }
}
