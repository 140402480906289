.main {
  background-color: #eee;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
}
.main div[tabindex] {
  height: 100%;
}
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem min(2%, 65px);
  background-color: #e9e9e9;
}

.topbar h1 {
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.topbar button {
  outline-color: #00962c;
  background: none;
  border: 0;
  cursor: pointer;
  display: grid;
  place-content: center;
}

.topbar > * {
  margin-bottom: 0 !important;
}
.content {
  padding: 1rem min(2%, 65px);
  overflow: hidden;
}
.content > div {
  background-color: #fff;
  height: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
}
.content > div > * {
  height: 100%;
  overflow-y: auto;
}

.bell {
  display: none !important;
}
@media screen and (min-width: 767px) {
  .bell {
    display: grid !important;
  }
  .toggle {
    display: none !important;
  }
  .topbar h1 {
    font-weight: 700;
  }
  .content {
    overflow-y: auto;
  }
}
