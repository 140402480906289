@font-face {
  font-family: "Circular STD";
  src: url("./assets/fonts/Circular\ Std\ Bold/Circular\ Std\ Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Circular STD";
  src: url("./assets/fonts/Circular\ Std\ Black/Circular\ Std\ Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Circular STD";
  src: url("./assets/fonts/Circular\ Std\ Bold\ Italic/Circular\ Std\ Bold\ Italic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Circular STD";
  src: url("./assets/fonts/Circular\ Std\ Book/Circular\ Std\ Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Circular STD";
  src: url("./assets/fonts/Circular\ Std\ Medium/Circular\ Std\ Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

.text-white {
  color: #fff;
}
.text-center {
  text-align: center;
}

.bg-white {
  background-color: #fff;
}
.w-100 {
  width: 100%;
}
.p-1 {
  padding: 1rem;
}
.ml-auto {
  margin-left: auto;
}
.c-end {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.table-row ::first-letter {
  text-transform: capitalize;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #92c064;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00962c;
}

.ant-card-meta-title {
  text-transform: capitalize !important;
}

.end--buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.ant-modal-title {
  text-transform: capitalize !important;
}
