*::-webkit-scrollbar {
  height: 2px;
}
.container {
  background-color: #fff;
  width: 100%;
  padding: 1rem 1rem;
  height: 100%;
  /* overflow-y: auto; */
  overflow-x: auto;
  border-radius: 9px;
}
.table-wrap {
  border: 3px solid red;
  overflow-x: auto;
}
.topbar {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.details-container {
  background-color: #fff;
  padding: 0.8rem;
  height: 100%;
}
.details-card-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.details-topbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .details-card-container {
    flex-flow: row wrap;
  }
  .details-topbar {
    justify-content: space-between;
    align-items: center;
  }
}

.details-topbar > *:not(:first-child) {
  padding-top: 0.5rem;
}
.details-card {
  background-color: #f9f9f9;
  width: 100%;
  padding: 1.3rem;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: space-between;
}
.details-card b {
  text-align: right;
}
.details-card:not(:last-child) {
  margin-bottom: 1rem;
}

.details-card > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.8em;
}
.details-card b {
  text-transform: capitalize;
}
.details-card > span:not(:last-child) {
  padding-bottom: 1em;
}

.details-card > span b {
  text-align: left;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media (min-width: 1000px) {
  .details-card {
    width: 49%;
  }

  .details-card:not(:last-child) {
    margin-bottom: 0rem;
  }
  .details-topbar > *:not(:first-child) {
    padding-top: 0rem;
  }
  .details-card > span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;
  }
  .details-card > span b {
    text-align: right;
  }
}
/* .footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}
@media (min-width: 600px) {
  .footer {
    justify-content: flex-end;
  }
} */

.profile-card {
  background-color: #f9f9f9;
  padding: 1rem;
  width: 100%;
  margin: 2% auto 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.avatar {
  display: block;
  border-radius: 1rem;
  /* margin: 2rem auto; */
  position: relative;
  width: 49%;
}
.profile-content {
  margin-left: 2rem;
}
.circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 50px solid #00962c11;
}
.circle:nth-child(odd) {
  top: -40%;
  right: -20%;
}
.circle:nth-child(even) {
  bottom: -20%;
  left: -10%;
  border: 40px solid #00962c11;
}

.table-container {
  overflow-x: auto;
  /* margin-right: 1rem; */
  width: 100%;
}
.table-row {
  font-size: 0.9rem;
  width: 100%;
  /* padding: 0 0.5rem; */
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}

.waybill-container {
  width: 100%;
  padding: 2rem;
  text-transform: capitalize;
}
.top-address {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.left .right {
  width: 30%;
}
.waybill-container table {
  width: 80%;
  margin: 0 auto;
  /* padding: 1rem; */
}

.waybill-container table td {
  padding: 0.6rem;
}

.waybill-container table,
.waybill-container th,
.waybill-container td {
  border: 1px solid black;
  border-collapse: collapse;
}

.delivery-comment {
  height: 100px;
}
.table-margin {
  margin-top: 3rem;
}

@media (max-width: 600px) {
  .avatar {
    width: 100%;
  }
}

.time-up {
  opacity: 0.4;
  filter: grayscale(100%);
}
