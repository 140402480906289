.item {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem 1rem;
  border: 1px solid transparent;
  border-top: 1px solid #00962c;
  box-shadow: 0px 2px 5px #ddd;
}
.item:not(:last-child) {
  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .item:not(:last-child) {
    margin-bottom: 0rem;
  }
}
.item:hover {
  cursor: pointer;
  border: 1px solid #00962c;
}
.item-img {
  padding: 0.6rem 0.8rem;
  background-color: #00962c1a;
  border-radius: 15px;
}
.item-text {
  padding: 0;
  width: 100%;
  /* height: 50; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-left: 0.5rem;
}
.item-text * {
  text-transform: capitalize;
}
.item-main-text,
.item-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .sub-title h5 {
    line-height: 1.5;
  }
}
